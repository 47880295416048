<template>
  <div class="well well-lg registration">
    <div class="row">
        <div class="col-sm-offset-4 col-sm-4">
            <form class="form-horizontal" @submit.prevent="submit">

                <div class="text-center">
                    <h2>{{ $t('register.create-account') }}</h2>
                    <div v-if="error">
                        <div class="invalid-feedback">
                            <div>{{ $t('register.phone-number-error') }}</div>
                        </div>
                    </div>
                </div>
        
                <br>
                <div class="form-group">
                    <label class="" for="email">{{ $t('register.phone-number') }}:</label>
                    <div class="">
                        <vue-tel-input 
                            maxLen="20"
                            required="true"
                            validCharactersOnly="true"
                            v-model="user.phone"
                            :defaultCountry="geo.country_code2"
                            mode="international"
                            :inputOptions="options"
                            @country-changed="countryChanged"
                        ></vue-tel-input>
                    </div>
                </div>
        
                <div class="form-group">
                    <label class="" for="pwd">{{ $t('register.password') }}:</label>
                    <div class="" style="position: relative;">
                        <input :type="passwordFieldType" id="password" class="form-control"  v-model.trim="$v.user.password.$model" :class="{ 'is-invalid' : $v.user.password.$erros }" maxlength="6" :placeholder="this.$t('header.place_pass')">
                        <i class="fa fa-eye field-icon" aria-hidden="true" v-if="!show" @click="switchVisibilty"></i>
                        <i class="fa fa-eye-slash field-icon" aria-hidden="true" v-if="show" @click="switchVisibilty"></i>
                        <div v-if="$v.user.password.$error" class="invalid-feedback">
                            <div v-if="!$v.user.password.required">{{ $t('header.required') }}</div>
                            <div v-if="!$v.user.password.minLength">{{ $t('header.password-error-less') }}</div>
                            <div v-if="!$v.user.password.maxLength">{{ $t('header.password-error-more') }}</div>
                        </div>
                    </div>
                </div>
        
                <div class="form-group">
                    <label class="" for="confirmPassword">{{ $t('register.password-confirm') }}:</label>
                    <div class="" style="position: relative;">
                        <input :type="passwordFieldType" id="confirmPassword" class="form-control"  v-model.trim="$v.user.confirmPassword.$model" :class="{ 'is-invalid' : $v.user.confirmPassword.$erros }" maxlength="6" :placeholder="this.$t('header.place_pass')">
                        <i class="fa fa-eye field-icon" aria-hidden="true" v-if="!show" @click="switchVisibilty"></i>
                        <i class="fa fa-eye-slash field-icon" aria-hidden="true" v-if="show" @click="switchVisibilty"></i>
                        <div v-if="$v.user.confirmPassword.$error" class="invalid-feedback" required>
                            <div v-if="!$v.user.confirmPassword.required">{{ $t('header.required') }}</div>
                            <div v-if="!$v.user.confirmPassword.minLength">{{ $t('header.password-error-less') }}</div>
                            <div v-if="!$v.user.confirmPassword.maxLength">{{ $t('header.password-error-more') }}</div>
                            <div v-if="!$v.user.confirmPassword.sameAsPassword">{{ $t('register.password-confirm-error') }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="">
                        <!-- -->
                        <p>{{ $t('register.agreement-1') }} <router-link  to="/terms-and-conditions" class="terms-link">{{ $t('register.agreement') }} </router-link>{{ $t('register.agreement-2') }}</p> 
                    </div>
                </div>
                <div class="form-group">
                    <div class="">
                        <button type="submit" class="btn btn-default">{{ $t('header.register') }}</button>
                        <router-link class="btn btn-default" to="verification">{{ $t('header.already-have-otp-code') }} </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
    

</div>
</template>

<script>
import { required, minLength, maxLength,sameAs } from 'vuelidate/lib/validators';
import AuthService from "@/services/auth";
import { mapGetters } from "vuex";
export default {
    name: 'register',
    props: {
        msg: String
    },
    computed: {
        ...mapGetters({
            geo:'geo',
        })
    },
    data() {
        return {
            user: {
                password: '',
                phone:'',
                confirmPassword:'',
                iso:''
            },
            options: {
                placeholder: this.$i18n.t('header.place_phone')
            },
            show: false,
            zones: [],
            error: '',
            passwordFieldType: 'password'
        }
    },
    validations: {
        user: {
            phone: {
                required:required,
            },
            password: {
                required:required,
                minLength: minLength(6),
                maxLength: maxLength(6),
            },
            confirmPassword: {
                required:required,
                minLength: minLength(6),
                maxLength: maxLength(6),
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {},
    methods: {
        submit: function() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            console.log(this.user);

            let str = this.user.phone;
            str = str.replace(/\s/g, "");
            this.user.phone = str.replace(/\+/g, "");
            
            AuthService.register(this.user)
            .then( res => {
                if(res.success === false) {
                    this.error = true
                    this.$swal.fire(this.$i18n.t('register.phone-number-error'),'','error' )
                } else {
                    this.error = false
                    this.$swal.fire('','','success' );
                    this.$router.push("/verification")
                }
            })
            .catch( () => this.$swal.fire(this.$i18n.t('register.phone-number-error'),'','error' ) )
        },
        switchVisibilty: function() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            this.show = this.passwordFieldType === 'password' ? true : false
        },
        countryChanged(country) {
            this.user.iso = country.iso2;
        }
    }
}
</script>

<style >
    .registration .btn-default {
        margin-bottom: 10px;
    }
    .registration .field-icon {
        margin-left: -30px;
        margin-top: -25px;
        position: absolute;
        z-index: 2;
        top: 35px;
        color: #000;
        right:10px;
    }
    .registration .vti__input {
        color : var(--bg-dark-medium)
    }
    .registration .vti__dropdown-item {
        color : var(--bg-dark-medium)
    }
    .registration .vti__dropdown {
        background-color: var(--a-text);
    }
    .registration .terms-link {
        color: #f7941e;    
    }
</style>